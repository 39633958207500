<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.794" x2="0.347" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_openbaarvervoer" transform="translate(-375 -2337)">
    <rect :fill="background" data-name="Rectangle 184" height="183" id="Rectangle_184" transform="translate(375 2337)" width="183"/>
    <g data-name="MDI / train" id="MDI_train" transform="translate(406 2367.583)">
     <rect :fill="background" height="121.833" id="Boundary" opacity="0" width="121.833"/>
     <path d="M44.611,2C24.306,2,4,4.538,4,22.306V70.531A17.767,17.767,0,0,0,21.767,88.3l-7.615,7.615v2.538h11.32L35.626,88.3H54.764L64.917,98.451H75.069V95.913L67.455,88.3A17.767,17.767,0,0,0,85.222,70.531V22.306C85.222,4.538,67.049,2,44.611,2M21.767,78.146a7.615,7.615,0,1,1,7.615-7.615,7.615,7.615,0,0,1-7.615,7.615M39.535,42.611H14.153V22.306H39.535V42.611m10.153,0V22.306H75.069V42.611H49.687M67.455,78.146a7.615,7.615,0,1,1,7.615-7.615A7.615,7.615,0,0,1,67.455,78.146Z" data-name="Path / train" fill="url(#linear-gradient)" id="Path_train" transform="translate(16.306 13.229)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>